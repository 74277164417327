import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import { matchPath } from "react-router";
import zendesk from "helpers/zendesk";
import { getAuth } from "modules/auth/actions";
import { useGetUserIsAnonymous, useIsUserLoaded } from "modules/selectors";
import usePrevious from "hooks/usePrevious";
import SideNavbar from "app/components/SideNavbar";
import Flex from "app/components/Flex";
import { Navbar } from "app/components/Navbar";
import AddClassModal from "app/components/AddClassModal";
import ExperimentLabel from "app/components/ExperimentLabel";
import ToastContainer from "app/components/ToastContainer";
import env from "helpers/env";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import { Routes } from "constants/routes";
import { useGCLIDTracking } from "hooks/useGetGclid";
import { Routes as RoutesToRender } from "./routes";
import { PreviousLocationProvider } from "./components/PreviousLocation";
import { useOneSignal } from "./useOneSignal";

const HIDE_NAVBAR_ROUTES = [Routes.upgrade, Routes.checkout, Routes.register];
const REMOVE_PADDING_ROUTES = [
  Routes.login,
  Routes.checkout,
  Routes.register,
  Routes.upgrade,
  Routes.party,
  Routes.community,
  Routes.not_found,
  Routes.class__id,
  Routes.class__id__preview,
  Routes.programs__slug,
];
const HIDE_ZENDESK_WIDGET_ROUTES = [
  Routes.checkout,
  Routes.community,
  Routes.challenges__id,
  Routes.challenges__post__id,
  Routes.post__id,
];
const HIDE_SIDENAV_ROUTES = [
  Routes.login,
  Routes.upgrade,
  Routes.checkout,
  Routes.register,
];
const GREY_BACKGROUND_ROUTES = [Routes.community, Routes.post__id];

const Wrapper = styled(Flex).attrs({
  height: "100%",
  overflow: "hidden",
})``;

const ContentWrapper = styled(Flex)`
  display: flex;
  width: 100%;
  overflow: auto;
`;

const RouteWrapper = styled(Flex)`
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
`;

const RenderedRoutes = ({
  routeWrapperPadding,
  isModal,
  previousLocation = null,
}) => {
  return (
    <RouteWrapper flexDirection="column" width="100%" p={routeWrapperPadding}>
      <RoutesToRender isModal={isModal} previousLocation={previousLocation} />
    </RouteWrapper>
  );
};

RenderedRoutes.propTypes = {
  routeWrapperPadding: PropTypes.string.isRequired,
  isModal: PropTypes.bool.isRequired,
  previousLocation: PropTypes.shape({}),
};

RenderedRoutes.defaultProps = {
  previousLocation: null,
};

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isAnonymous = useGetUserIsAnonymous();
  const { isFamilyModeOn, loadingIsFamilyModeOn } = useFamilyModeSetting();

  const registerStepIndex = useSelector(
    ({ components }) => components.Register.stepIndex
  );
  const currentNavbarHeight = useSelector(
    ({ components }) => components.Navbar.height
  );
  const addClassModalData = useSelector(
    ({ components }) => components.AddClassModal.classData
  );
  const userLoaded = useIsUserLoaded();

  const previousRegisterStepIndex = usePrevious(registerStepIndex);
  const previousPathname = usePrevious(location.pathname);
  const previousLocation = usePrevious(location);
  const customPreviousLocation = location.state?.previousLocation;

  const { pathname } = location;

  const isModal = Boolean(
    location.state?.modal && previousLocation && previousLocation !== location
  );

  const showZendeskWidget = isAnonymous && isFamilyModeOn ? "hide" : "show";

  useGCLIDTracking();
  useOneSignal();

  useEffect(() => {
    if (window.rudderanalytics) {
      window.rudderanalytics.load(
        env("PUBLIC_RUDDERSTACK_KEY"),
        env("PUBLIC_RUDDERSTACK_DATAPLANE_URL")
      );
    }
    dispatch(getAuth());
    zendesk("webWidget", "hide");
  }, [dispatch]);

  useEffect(() => {
    if (pathname && pathname !== previousPathname) {
      window.rudderanalytics.page();
    }

    if (
      pathname &&
      HIDE_ZENDESK_WIDGET_ROUTES.some(path =>
        matchPath(pathname, { path, exact: true })
      )
    ) {
      zendesk("webWidget", "hide");
    } else {
      zendesk("webWidget", showZendeskWidget);
    }
  }, [pathname, previousPathname, showZendeskWidget]);

  useEffect(() => {
    if (
      pathname === "/register" &&
      (registerStepIndex === 0 ||
        registerStepIndex !== previousRegisterStepIndex)
    ) {
      window.scrollTo(0, 0);
    }
  }, [pathname, previousRegisterStepIndex, registerStepIndex]);

  const paddingFromNavbar = `${
    !HIDE_NAVBAR_ROUTES.includes(location.pathname)
      ? currentNavbarHeight || 80
      : 0
  }px`;
  const routeWrapperPadding = REMOVE_PADDING_ROUTES.some(path =>
    matchPath(location.pathname, {
      path,
      exact: true,
    })
  )
    ? `${paddingFromNavbar} 0 0`
    : `${paddingFromNavbar} 0 100px 0`;

  const doesRouteHaveGreyBackground = GREY_BACKGROUND_ROUTES.some(path =>
    matchPath(location.pathname, {
      path,
      exact: true,
    })
  );

  return (
    <Wrapper color="black">
      <ToastContainer
        position="bottom-left"
        autoClose={4500}
        hideProgressBar
        closeOnClick={false}
      />
      {!HIDE_NAVBAR_ROUTES.includes(location.pathname) && (
        <Navbar isAnonymous={isAnonymous} loaded userLoaded={userLoaded} />
      )}
      {(isAnonymous || userLoaded) && !loadingIsFamilyModeOn && (
        <>
          {!HIDE_SIDENAV_ROUTES.includes(location.pathname) &&
            !isFamilyModeOn && (
              <SideNavbar isAnonymous={isAnonymous || !userLoaded} />
            )}
          <ContentWrapper
            bg={doesRouteHaveGreyBackground ? "monochrome.1" : "white"}
          >
            <ExperimentLabel />
            <PreviousLocationProvider>
              <RenderedRoutes
                routeWrapperPadding={routeWrapperPadding}
                isModal={isModal}
                previousLocation={customPreviousLocation}
              />
            </PreviousLocationProvider>
          </ContentWrapper>
        </>
      )}
      {(isAnonymous || userLoaded) && addClassModalData && (
        <AddClassModal
          classId={addClassModalData.id}
          programClassRefId={addClassModalData.refId}
          isOpen={parseInt(addClassModalData.id) >= 0}
        />
      )}
    </Wrapper>
  );
};

export default App;
